.radio input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;
  min-width: 20px !important;
  min-height: 20px !important;
  border: 1px solid #c7ccd1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio input[type="radio"]::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 10px 10px #0066FF;
  display: block;
}

.radio input[type="radio"]:checked::before {
  transform: scale(1);
}

.radio input[type="radio"]:checked {
  border-color: #0066FF;
}

.radio {
  gap: 8px;
  display: flex;
  align-items: center;
}

.radio {
  /* color: #505962; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
