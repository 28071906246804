@font-face {
  font-family: AdsitionFont;
  src: url(./OpenSans-Regular.ttf);
  font-weight: 400;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: AdsitionFont;
  src: url(./OpenSans-Medium.ttf);
  font-weight: 500;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: AdsitionFont;
  src: url(./OpenSans-SemiBold.ttf);
  font-weight: 600;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: AdsitionFont;
  src: url(./OpenSans-Bold.ttf);
  font-weight: 700;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: AdsitionFont;
  src: url(./OpenSans-ExtraBold.ttf);
  font-weight: 800;
  unicode-range: U+0020-007F;
}
