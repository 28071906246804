@font-face {
  font-family: AdsitionFont;
  src: url(./DanaFaNum-Regular.woff);
  font-weight: 400;
  unicode-range:U+0600-06FF;
}

@font-face {
  font-family: AdsitionFont;
  src: url(./DanaFaNum-Medium.woff);
  font-weight: 500;
  unicode-range:U+0600-06FF;
}

@font-face {
  font-family: AdsitionFont;
  src: url(./DanaFaNum-DemiBold.woff);
  font-weight: 600;
  unicode-range:U+0600-06FF;
}

@font-face {
  font-family: AdsitionFont;
  src: url(./DanaFaNum-Bold.woff);
  font-weight: 700;
  unicode-range:U+0600-06FF;
}

@font-face {
  font-family: AdsitionFont;
  src: url(./DanaFaNum-ExtraBold.woff);
  font-weight: 800;
  unicode-range:U+0600-06FF;
}
