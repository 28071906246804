@import url(./assets/fonts/Dana/styles.css);
@import url(./assets/fonts/OpenSans/styles.css);

p,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
input,
div,
a {
  font-family: AdsitionFont !important;
  line-height: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
}

.bg-transparent {
  background-color: transparent;
}

input[type="checkbox"]:checked {
  content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3332 4.33301L5.99984 11.6663L2.6665 8.33301' stroke='%23F8F9FA' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

input[type="checkbox"].error:checked {
  content: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5368 9.66717L1.98602 1.11639C1.74376 0.874129 1.35097 0.874129 1.1087 1.11639C0.866439 1.35866 0.866439 1.75145 1.1087 1.99371L9.65947 10.5445C9.90174 10.7867 10.2945 10.7867 10.5368 10.5445C10.7791 10.3022 10.7791 9.90943 10.5368 9.66717Z' fill='%23F8F9FA'/%3E%3Cpath d='M1.98469 10.5304L10.5355 1.97968C10.7777 1.73741 10.7777 1.34462 10.5355 1.10236C10.2932 0.860091 9.90041 0.860091 9.65814 1.10236L1.10737 9.65313C0.865106 9.89539 0.865106 10.2882 1.10737 10.5304C1.34964 10.7727 1.74242 10.7727 1.98469 10.5304Z' fill='%23F8F9FA'/%3E%3C/svg%3E");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: none !important;
  transition: background-color 50000000000000s ease-in-out 0s;
}

body,
html {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  font-family: AdsitionFont !important;
  scroll-behavior: smooth;
}

#root {
  width: 100%;
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.react-date-picker__clear-button {
  display: none;
}

.react-date-picker__calendar-button {
  display: none;
}

.react-date-picker__wrapper {
  padding: 13px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 14px;
  border: 1px solid #e3e6e8 !important;
}

html[dir="rtl"] .react-date-picker__wrapper {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

html[dir="rtl"] .datepicker-input {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.datepicker-input {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #e3e6e8;
  background: #fff;
  padding: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  outline: none !important;
}

.scrollbar-con div::-webkit-scrollbar {
  display: none;
}

.safari-only {
  padding-bottom: 65px;
}

.Toastify__toast {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0;
}

.Toastify__close-button {
  display: none !important;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.Toastify__toast-container {
  width: 420px !important;
}

.react-modal-sheet-backdrop {
  display: none;
}

@layer {
  html[dir="rtl"] .crisp-client a {
    left: 24px !important;
    right: calc(100vw - 84px) !important;
    width: fit-content !important;
  }
}

html[dir="rtl"] #crisp-chatbox > div:first-child > div:first-child {
  left: 24px !important;
  right: unset !important;
}

@media only screen and (max-width: 768px) {
  .Toastify__toast-container {
    width: 90% !important;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
